.nft-list-for-sale-page-form {
  position: relative;
  height: 100%;

  .content {
    &__price {
      text-align: right;
    }
  }

  .field {
    position: relative;

    &__button {
      position: absolute;
      right: 10px;
      top: 8px;
      height: 25px;
    }

    &__currency {
      position: absolute;
      right: 10px;
      top: 10px;

      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: $color_scarlet;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}
.whitelist-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
