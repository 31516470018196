@import './search-form';

.nft-management-page {
  &__row {
    align-items: center;
  }

  &__col {
    &--last {
      display: inline-flex;
      justify-content: flex-end;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__app-tab {
    .ant-tabs-content-holder {
      background-color: white;
      padding: 20px;
      border-radius: 10px;
      border-top-left-radius: 0px;
    }
  }

  .item-with-label {
    color: $color_trout;

    .content {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: capitalize;
    }
  }

  &__button {
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_white;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: $color_mercury !important;
    }
  }

  .ant-popover-inner {
    border-radius: 10px;
  }

  .ant-popover-inner-content {
    padding: 0px;
    border-radius: 10px;

    ul {
      list-style-type: none;
      margin: 0px;
      box-shadow: 0px 16px 64px rgb(31 47 70 / 20%);
      border-radius: 10px;
      padding: 10px;

      li {
        padding: 6px 12px;
        font-weight: 600;
        color: #777e90;
        font-size: 14px;
        line-height: 22px;
        white-space: nowrap;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;

        &:hover {
          color: $color_scarlet;
        }
      }
    }
  }
}
