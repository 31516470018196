.nft-detail-group-button-header {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1;

  button {
    margin-right: 10px;
    height: 36px;
    width: 100%;
    max-width: 145px;
  }

  &__menu {
    .item {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.015em;
      color: $color_trout;
      border-bottom: 1px solid $color_gray;
      padding: 14px 0px;
      cursor: pointer;
    }
  }
}
