.nft-detail-info {
  padding-top: 36px;

  &__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $color_mine;
  }

  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_trout;
    padding-top: 6px;
    padding-bottom: 10px;
  }

  &__status {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 14px;
    width: fit-content;
    padding: 5px 13px;
  }

  &__description {
    @include scrollbars();
    overflow: auto;
    max-height: 63px;
    font-weight: 400;
    white-space: pre-line;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_trout;
    margin-bottom: 48px;
    padding-right: 10px;
  }

  &__item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 14px;

    .label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $color_tundora;
      padding-bottom: 4px;
      margin-right: 3px;
    }

    .content {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.015em;
      color: $color_trout;
    }

    .attribute {
      display: inline-flex;
      align-items: center;
      flex-flow: wrap;

      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
        margin-right: 6px;
      }

      &__text {
        max-width: 150px;
      }
    }
  }

  .item-with-label {
    .icon {
      padding-left: 3px;
    }
  }
}
