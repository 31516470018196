@mixin hover() {
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

@mixin textEllipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin scrollbars() {
  &::-webkit-scrollbar {
    width: 14px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px $color_trout_0_3;
    border: solid 4px transparent;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 14px 14px $color_trout;
    border: solid 4px transparent;
    border-radius: 14px;
  }
}
