.dashboad-recent-transaction {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: $color_trout;
  }

  &__table {
    .ant-table-thead .ant-table-cell {
      background-color: transparent;

      &::before {
        background-color: transparent !important;
      }
    }
  }
}
