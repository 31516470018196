.nft-creation-page-preview {
  position: sticky;
  top: 20px;

  &__header {
    font-weight: 600;
  }

  .preview-placeholder {
    border: 1px dashed $color_waterloo;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 400px;

    &__description {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.015em;
      color: $color_waterloo;
      padding-top: 8px;
    }
  }
}