.confirm-modal {
  padding: 36px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
    padding-bottom: 12px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.015em;
    color: $color_trout;
  }

  &__button-group {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 44px;

    .button {
      width: 50%;
    }
  }
  &__button-group-wl {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 44px 20px;

    .button {
      width: 50%;
    }
  }
  .deposit-attribute {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .attribute-title {
      font-size: 16px;
      font-weight: 600;
    }
    .attribute-title-result {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .deposit-form {
    .deposit-amount {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      input:focus-visible {
        outline: none;
      }
      .deposit-input-amount {
        border: 1px solid #d9d9d9;
        height: 35px;
        border-radius: 5px;
        padding: 0px 10px;
      }

      .deposit-amount-text {
        font-size: 18px;
        font-weight: 700;
      }
      button {
        position: absolute;
        right: 170px;
      }
    }
  }
}
.ant-divider {
  border-top: 1px solid black;
}
.deposit-modal {
  button {
    display: flex;
    margin: auto;
    width: 200px;
  }
}
.minimum-reward-modal {
  padding: 40px;
  background: #3c3c3c;
  border-radius: 10px;
  color: white;
  .minimum-reward-title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}
