.nft-creation-page-content {
  margin-bottom: 20px;

  .content {
    &__title {
      padding-bottom: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $color_cinder;
    }

    &__sub-title {
      padding-bottom: 15px;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.015em;
      color: $color_trout;
    }
  }

  .preview-content {
    padding-top: 20px;

    &__title {
      padding-bottom: 15px;
      font-weight: 600;
    }

    &__sub-title {
      padding-bottom: 15px;
    }
  }
}
