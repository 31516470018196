.notification {
  border-radius: 5px;

  .app-dropdown {
    padding: 20px;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: $color_trout;
  }
}

.notification-icon {
  cursor: pointer;
}

.notification-card {
  border-radius: 5px;
  width: 100%;
  max-width: 394px;
  height: auto;

  .group {
    gap: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color_gray;
    cursor: pointer;

    &:last-child {
      border-bottom: unset;
    }

    .content {
      width: 95%;

      .text {
        width: 100%;
        padding-top: 14px;
        @include textEllipsis(1);
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.015em;
        color: $color_trout;
      }

      .sub-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #c4c4c4;
        padding-top: 2px;
        padding-bottom: 12px;

        span:nth-child(2) {
          margin-left: 4px;
        }
      }
    }

    .effect {
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $color_radical;
      }
    }
  }

  .notification-empty-text {
    padding: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: $color_titan_white;
    padding-bottom: 4px;
    text-align: center;
  }

  .infinite-scroll-component {
    overflow: auto;
    overflow-x: hidden !important;
    max-height: 350px;
    padding-right: 20px;
    @include scrollbars();
    padding: 0 10px;
  }
}
