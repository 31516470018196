.nft-management-search-form {
  &__row {
    align-items: center;
    padding-bottom: 20px;
  }

  &__group-button {
    .button {
      margin-right: 10px;
      padding: 12px 23px;
    }
  }

  ::placeholder {
    color: $color_waterloo !important;
  }
  .ant-select-selection-placeholder {
    color: $color_waterloo !important;
  }
}
