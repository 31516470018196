.nft-detail-page-remove-from-sale-modal {
  .button {
    width: 100%;
    max-width: 375px;
    height: 45px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    margin: 20px auto 0px;
    display: flex;
    justify-content: center;
    color: $color_white;
  }
}
