@import './nft-detail-group-button-header';
@import './remove-from-sale-button';
@import './delete-button';
@import './nft-profile';
@import './info';
@import './nft-owner-listing';
@import './search-form';
@import './nft-sale-history';
@import './warning-banner';

.nft-detail-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
