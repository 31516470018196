.nft-sale-history {
  &__txt-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item-with-label {
    color: $color_trout;

    .content {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: capitalize;
    }

    .label {
      margin-right: 0px;
    }

    .icon {
      margin: 0px 3px;
    }
  }
}
