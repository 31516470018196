.wrong-network-modal {
  padding: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    padding-top: 12px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;
    color: $color_black;
    font-weight: 600;
  }

  .sub-title {
    max-width: 400px;
    font-size: 14px;
    line-height: 21px;
    color: $color_trout;
    text-align: center;
    margin: auto;
    padding-top: 8px;
    max-width: 422px;
  }
}
