.sale-detail-modal {
  padding: 36px;

  &__row {
    padding-top: 36px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
  }

  .time-sale {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.015em;
    color: $color_trout;
  }

  .general {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 42px;
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_trout;
    padding-bottom: 6px;

    &__label {
      display: flex;
      align-items: center;

      .royalties-percent {
        margin-left: 3px;
      }
    }

    &__quantity {
      margin-bottom: 10px;
    }

    &__content {
      .content-currency {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .app-icon {
          width: 25px;
          height: 25px;
          margin-right: 8px;
        }

        .value {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: $color_scarlet;
        }

        .currency {
          padding-left: 3px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          color: $color_scarlet;
          opacity: 0.5;
        }
      }

      .content-usd {
        display: flex;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.015em;
        color: $color_trout;

        &__admin {
          margin-right: 3px;
        }
      }
      .address {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.015em;
        color: $color_trout;

        &__admin {
          margin-right: 3px;
        }
      }
    }

    a {
      margin-top: 75px;
    }

    .button-detail {
      width: 100%;
      padding: 8px 23px;
      border-radius: 5px;
    }
    .button-bscscan {
      width: 100%;
      padding: 8px 23px;
      background-color: $color_scarlet;
      color: $color_white;
      border-radius: 5px;
    }

    a {
      width: 47%;
    }
  }

  .line-sale-detail {
    border-bottom: 1px solid $color_gray;
  }

  .preview-nft {
    height: 87%;

    &__content {
      height: 100%;
    }
  }
}
