@import './loading-metamask-modal';
@import './metamask-notfound-modal';
@import './wrong-network-modal';
@import './confirm-modal';
@import './mint';
@import './whitelist';
@import './step';
@import './sale-detail';
@import './export-modal';

.modal {
  &-close-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
