@import './fee-rate';
.setting-page {
  button {
    margin-top: 17px;
    height: fit-content;

    svg {
      margin-right: 10px;
    }
  }
}
