.revenue-management-sale {
  margin-top: 28px;

  .col-item {
    background: $color_white;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 10px 20px;
    max-width: 24%;

    .label {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.015em;
      color: $color_cinder;

      img {
        padding-left: 3px;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-transform: capitalize;
      color: $color_scarlet;
      margin-bottom: 4px;
      &__app-icon {
        padding-right: 5px;
      }

      &__currency {
        padding-left: 3px;
        color: rgb(239 114 36 / 50%);
      }

      &__value {
        color: $color_trout;
      }
    }
    .usd {
      display: flex;
      flex-direction: row;
      margin-left: 32px;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: $color_trout;
    }
  }
}
