.login-page {
  height: 100vh;

  &--left {
    height: 100%;
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .content {
    width: 100%;
    text-align: center;
    padding: 0 15%;

    .title {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      text-transform: capitalize;
      color: $color_black;
      padding-top: 20px;
      font-weight: 600;
    }

    .sub-title {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: $color_trout;
      padding-bottom: 32px;
      font-weight: 400;
    }

    &__button {
      width: 100%;
      padding: 12px 24px;
      height: 60px;
      border: 1px solid $color_gray;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        &__icon {
          height: 37px;
          width: 40px;
          margin-right: 12px;
        }

        &__text {
          font-size: 16px;
          line-height: 24px;
          text-transform: capitalize;
          color: $color_black;
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 17px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $color_trout;
  }
}
