.app-address {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    align-self: center;
    margin-left: 6px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}
