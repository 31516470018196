.tab {
  .ant-tabs-tab {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_waterloo;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color_scarlet;
  }

  .ant-tabs-ink-bar {
    background: $color_scarlet;
  }

  .tab-pane {
    color: $color_black;
  }

  .ant-tabs-nav {
    margin: 0px;
  }

  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-tab {
    padding: 7px 25px;
    margin: 14px 0 0 0;
    background-color: $color_wild_sand;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ant-tabs-tab-active {
    background-color: $color_white;
  }

  .page-content {
    border-top-left-radius: 0px;
  }
}
