.metamask-notfound-modal {
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;
    color: $color_black;
    padding-bottom: 20px;
    font-weight: 600;
  }

  .sub-title {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: $color_trout;
    max-width: 380px;
    padding-bottom: 20px;
  }

  .logo {
    width: 52px;
    height: 48px;
    margin-bottom: 20px;
  }

  .footer {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid $color_gray;
    border-radius: 25px;

    img {
      margin-right: 8px;
    }

    a {
      font-size: 14px;
      line-height: 21px;
      text-transform: capitalize;
      color: $color_scarlet;
      font-weight: 600;
      letter-spacing: 0.015em;
    }
  }
}
