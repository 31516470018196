.page-header {
  padding: 0;
  display: flex;
  align-items: center;

  &__back-button {
    margin-right: 17px;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: none;
    background-color: $color_white;
  }

  &__title {
    color: $color_mine;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.015em;
  }
}
