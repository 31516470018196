@import 'styles/variables';

.container {
  background: white;
  box-shadow: 0px 32px 64px -24px rgba(51, 54, 60, 0.15);
  border-radius: 16px;
  width: 100%;
  padding: 40px 44px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
