.nft-bulk--wrap {
  >.ant-row {
    margin-top: 32px;
  }

  .btn-group {
    .ant-btn {
      border-radius: 22px;
      min-width: 160px;
      height: 44px;
      font-weight: 600;
      font-size: 15px;
      width: unset;
      margin-top: 0;
    }

    &__discard {
      color: $color_store_button  !important;
      border: 2px solid $color_store_button  !important;
      margin-right: 20px;
      background-color: white !important;
      border: 2px solid $color_store_button  !important;
    }

    &__create {
      background-color: $color_store_button  !important;
      color: white !important;
    }

    &__delete {
      background-color: $color_status_red_text  !important;
      border-color: $color_status_red_text  !important;
      margin-left: 20px;
      color: white !important;
    }
  }

  .nft-bulk {
    &__card {
      // height: 100%;
      margin-bottom: 0;

      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: $color_black;
      }

      &__desc {
        line-height: 22px;
        color: $color_trout;
      }

      &__help {
        &:hover {
          color: $color_laurel;
        }

        color: $color_laurel;
        margin-top: 4px;
        width: fit-content;
      }

      &__upload {
        margin-top: 20px;
      }

      &__download-template {
        &:hover {
          color: $color_laurel;
        }

        color: $color_laurel;
        text-decoration: underline;
        font-weight: 500;
      }
    }

    &__table-error {
      border-radius: 0px !important;
      border: 1px solid $color_gray;

      thead tr,
      th {
        background-color: $color_titan_white  !important;
      }

      th {
        border-radius: 0px !important;
      }

      .ant-table-body {
        @include scrollbars();
      }
    }

    &__table-error.table th {
      border-bottom: 1px solid $color_gray  !important;
    }
  }

  .nft-bulk-content {
    &__folder {
      margin-top: 20px;

      &__icon {
        height: 32px;
        width: 32px;
        margin-right: 16px;
      }

      &__icon-delete {
        padding: 0px;
        margin-left: 37px;
        cursor: pointer;
      }

      &__progress {
        .ant-progress-bg {
          background-color: $color_laurel;
        }
      }

      &__name {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: black;
      }

      &__error {
        margin-top: 20px;

        >img {
          width: 22px;
          height: 19px;
          margin-right: 7px;
        }

        font-weight: 600;
        line-height: 14px;
        color: $color_french_rose;

        &--desc {
          font-weight: 400;
          color: $color_trout;
          margin: 11px 0;
        }

        &-table {
          .ant-table-body {
            @include scrollbars();
          }
        }
      }

      &__success {
        margin-top: 20px;

        >img {
          width: 22px;
          height: 19px;
          margin-right: 7px;
        }

        font-weight: 600;
        line-height: 14px;
        color: $color_laurel;
      }
    }
  }

  .modal-verify-bulk {
    text-align: center;
    padding: 50px 80px;
    border-radius: 16px;

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: $color_black;
      margin-bottom: 16px;
    }

    &__desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color_trout;
      margin-bottom: 32px;
    }

    &__icon {
      height: 64px;
      width: 64px;
      margin-top: 24px;
      margin-bottom: 32px;
    }

    &__button {
      padding: 0 30px;
    }

    .download-file-verify {
      display: block;

      &:hover {
        color: $color_laurel;
      }

      color: $color_laurel;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .confirm-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .description {
      margin-bottom: 32px;
    }
  }
}