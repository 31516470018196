.admin-creation-page {
  .admin-creation-page-form {
    margin-top: 20px;
    .admin-creation-page-attribute {
      padding: 50px;
      .admin-create-field {
        display: flex;
        justify-content: space-between;
        .label {
          font-size: 14px;
          color: #141320;
          font-weight: 600;
          width: 30%;
        }
        .field {
          width: 90%;
        }
        .field-swicth {
          display: flex;
          gap: 10px;
          width: 90%;
        }
      }
      .publish-time {
        margin-top: 42px;
        height: 35px;
      }
      .end-time-staking {
        width: 100%;
        height: 40px;
      }
      .end-time-pool {
        width: 100%;
        height: 40px;
      }
    }
    .admin-creation-page-group-button {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      button {
        width: 200px;
      }
    }
  }
}
