.dragger {
  height: 320px !important;

  &__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }

  &__label {
    color: $color_trout;
    padding-top: 16px;
  }

  &__text {
    color: $color_scarlet;
    text-decoration: underline;
  }

  .ant-upload {
    padding: 0px !important;
  }

  .ant-image-img {
    height: 250px;
  }

  video {
    height: 250px;
  }

  .model-viewer {
    height: 250px;
    margin: auto;

    model-viewer {
      height: 100%;
      width: 100%;
    }
  }
}
