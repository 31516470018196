// default
$color_default_button_background: white;
$color_default_button: $color_scarlet;
$color_default_button_border: $color_scarlet;

// link
$color_link_button_background: red;
$color_link_button: black;
$color_link_button_border: white;

// primary
$color_primary_button_background: $color_scarlet;
$color_primary_button: white;
$color_primary_button_border: $color_scarlet;

// secondary
$color_secondary_button_background: red;
$color_secondary_button: black;
$color_secondary_button_border: white;

.ant-btn:hover {
  opacity: 0.8;
}

.button {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 5px;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  &--default {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    background-color: $color_default_button_background;
    color: $color_default_button;
    border: 1px solid $color_default_button_border;

    &:focus,
    &:active,
    &:hover {
      color: $color_default_button;
      background-color: $color_default_button_background;
      border: 1px solid $color_default_button_border;
    }

    &:disabled {
      color: $color_default_button;
      background-color: $color_default_button_background;
      border: 1px solid $color_default_button_border;
    }
  }

  &--link {
    background-color: $color_link_button_background;
    color: $color_link_button;
    // border: 1px solid $color_link_button_border;

    &:focus,
    &:active,
    &:hover {
      color: $color_link_button;
      background-color: $color_link_button_background;
      // border: 1px solid $color_link_button_border;
    }

    &:disabled {
      border: none;
      color: $color_link_button;
    }
  }

  &--primary {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    background-color: $color_primary_button_background;
    color: $color_primary_button;
    border: 1px solid $color_primary_button_border;

    &:focus,
    &:active,
    &:hover {
      background-color: $color_primary_button_background;
      color: $color_primary_button;
      border: 1px solid $color_primary_button_border;
    }

    &:disabled {
      background-color: $color_primary_button_background;
      color: $color_primary_button;
      border: 1px solid $color_primary_button_border;
    }
  }

  &--secondary {
    background-color: $color_secondary_button_background;
    color: $color_secondary_button;
    // border: 1px solid $color_secondary_button_border;

    &:focus,
    &:active,
    &:hover {
      color: $color_secondary_button;
      background-color: $color_secondary_button_background;
      // border: 1px solid $color_secondary_button_border;
    }

    &:disabled {
      border: none;
      // color: $color_secondary_button;
    }
  }
}
