.dashboad-top-nfts {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: $color_trout;
  }

  &__tab {
    .ant-tabs-nav {
      &::before {
        border-bottom: unset;
      }
    }

    .ant-tabs-tab {
      background-color: transparent;
      padding: 5px;
      margin-top: 0px;
    }

    .ant-tabs-tab-active {
      border-bottom: 2px solid $color_scarlet;
    }

    .ant-tabs-nav-wrap {
      display: inline-flex;
      justify-content: flex-end;
    }

    .ant-tabs-extra-content {
      flex: 1;
      order: -1;
    }
  }

  &__table {
    .ant-table-thead .ant-table-cell {
      background-color: transparent;

      &::before {
        background-color: transparent !important;
      }
    }

    .content-single {
      display: flex;
      gap: 10px;
      align-items: flex-start;

      img {
        width: 24px;
        height: 24px;
      }

      .currency {
        .symbol {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          text-transform: capitalize;
          color: $color_trout;
        }

        .usd {
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: $color_silver;
        }
      }
    }
  }
}
