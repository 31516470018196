.export-modal {
  padding: 32px 40px;

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
  }

  &__body {
    .market-channel {
      display: flex;
      height: 40px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.015em;
      color: $color_trout;

      &__select {
        min-width: 135px;
        height: 40px;
      }
    }

    .export-date {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.015em;
      color: $color_trout;
      margin-top: 10px;

      &__picker {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
      }

      .picker-date {
        min-height: 40px;
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_white;
    background: $color_scarlet;
  }
}
