.nft-creation-page-list-for-sale {
  margin-bottom: 20px;
  .whitelist-time {
    letter-spacing: 0.015em;
    padding-bottom: 8px;
    padding-top: 20px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 21px;
    color: #141320;
    font-weight: 600;
    display: flex;
  }

  .ant-radio-group {
    display: grid;
    gap: 10px;
    margin-left: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $color_cinder;
    }
  }

  .content {
    &__price {
      text-align: right;
    }
  }

  .field {
    position: relative;

    &__button {
      position: absolute;
      right: 10px;
      top: 8px;
      height: 25px;
    }

    &__currency {
      position: absolute;
      right: 10px;
      top: 10px;

      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: $color_scarlet;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}
