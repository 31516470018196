.dashboad-overview {
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $color_trout;
    }

    .filter {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.overview-item {
  width: 100%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  background: $color_white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 18px;

  .item-with-label {
    margin-bottom: 8px;
  }

  .content-single {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    img {
      width: 24px;
      height: 24px;
    }

    .currency {
      .symbol {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: $color_scarlet;
        padding-bottom: 4px;
      }

      .usd {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $color_trout;
      }
    }
  }

  .content-multi {
    display: flex;
    align-items: center;

    .item-with-label {
      width: 50%;

      .label {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.015em;
        color: $color_trout;
      }

      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
