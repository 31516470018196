.nft-detail-search-form {
  &__row {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  &__col-date {
    max-width: 149px;
    min-width: 149px;
  }

  &__col-select {
    width: 177px;
    min-width: 177px;
  }

  &__date {
    height: 40px;
  }
}
