.mint-modal {
  padding: 36px;

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
    padding-bottom: 12px;
  }

  .mint-modal-form {
    padding: 0 50px;

    .field {
      position: relative;

      &__button {
        position: absolute;
        right: 10px;
        top: 5px;
        height: 30px;
      }
    }

    &__sub {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.015em;
      color: $color_cinder;
      margin-top: 5px;
      text-align: right;
    }

    &__note {
      background: $color_oasis;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      margin-top: 12px;

      img {
        margin-right: 12px;
      }

      .text {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.015em;
        color: $color_trout;
      }
    }

    &__button {
      width: 100%;
      margin-top: 32px;
    }

    &__input {
      .form-item {
        &__label {
          color: $color_black;
        }
      }
    }
  }
}
