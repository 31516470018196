.view-nft-name {
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 3px;
  }
}

.view-nft-detail {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.015em;
  text-decoration-line: underline;
  color: $color_scarlet;
  cursor: pointer;
}

.view-create-whitelist {
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: $color_scarlet;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
.action-nft-management {
  display: flex;
  gap: 5px;
  .text-disabled {
    cursor: not-allowed;
    a {
      pointer-events: none;
    }
  }
}

.view-nft-status {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.015em;
  border-radius: 3px;
  text-align: center;
  padding: 5px 0px;
  width: 70px;

  &-on-sale {
    color: $color_laurel;
    background-color: $color_lariel_0_3;
  }

  &-off-sale {
    color: $color_radical;
    background-color: $color_radical_0_3;
  }

  &-sold-out {
    color: $color_dove_gray;
    background-color: $color_silver_0_3;
  }
}

.view-nft-link {
  display: flex;
  gap: 10px;
  flex-flow: wrap;
}
