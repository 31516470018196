@import './nft-content';
@import './nft-attibute';
@import './nft-list-for-sale';
@import './nft-group-button';
@import './dragger-media';
@import './preview';

.nft-creation-page-form {
  margin-top: 17px;
}
