@import './search-form';
@import './sale';

.revenue-management-page {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .app-button {
      height: 36px;
      width: 100%;
      max-width: 145px;

      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      background-color: $color_scarlet;
      color: $color_white;
      border: 1px solid $color_scarlet;
    }

    .button-icon {
      padding-right: 3px;
    }
  }

  &__body {
    .ant-table-column-sorter {
      color: $color_charade_0_5;
    }
  }
}
