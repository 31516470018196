.app-loading {
  height: 100vh !important;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;

  img {
    top: 50% !important;
    left: 50% !important;
  }
}
