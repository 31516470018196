.nft-creation-page-attribute {
  margin-bottom: 20px;

  .field {
    position: relative;

    &__span {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
.append-input {
  position: absolute;
  right: 10px;
  top: 10px;
}
