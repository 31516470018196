.nft-owner-listing {
  margin-top: 36px;

  &__header {
    margin-bottom: 26px;
  }

  .item-with-label {
    color: $color_trout;

    .content {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
}
