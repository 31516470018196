html,
body {
  font-size: 14px;
  font-family: $blinker, 'Poppins', sans-serif;
  @include scrollbars();

  #root {
    height: 100%;

    .App {
      height: 100%;
    }
  }
}

.address-admin {
  font-weight: 600;
}

.active-status-admin {
  color: #079b1b;
}

.inactive-status-admin {
  color: #4f5250;
}

.address-admins-list {
  max-width: 100px;
}

.ant-menu-inline-collapsed {
  .ant-menu-title-content {
    opacity: 0;
  }
}

.w-100 {
  width: 100% !important;
}
.select-whitelists {
  line-height: 17px;
  .total-address {
    font-size: 12px;
  }
}
iframe {
  display: none;
}
.text-color-red {
  color: red;
  position: absolute;
  bottom: 85px;
  left: 115px;
}
.launch-time-col {
  .field {
    .ant-radio-wrapper {
      margin-right: 10px;
    }
  }
}
