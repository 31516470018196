.preview-nft {
  &__header {
    background: $color_big_stone;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    height: 38px;
    border-radius: 5px;
    margin-bottom: 8px;

    .info {
      display: inline-flex;
      align-items: center;

      &__id {
        font-weight: 700;
        font-size: 18px;
        line-height: 16px;
        color: $color_white;
        padding-right: 6px;
      }

      &__dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $color_amber;
        margin-right: 6px;
      }

      &__class {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $color_titan_white;
      }
    }

    .skill {
      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    }
  }

  &__content {
    background: $color_big_stone;
    padding: 12px;
    border-radius: 5px;

    .info {
      position: relative;

      .ant-image {
        width: 100%;
      }

      &__image {
        width: 100%;
        height: 247px;
        object-fit: cover;
        border-radius: 5px;
      }

      &__footer {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        bottom: 12px;
        padding: 0 12px;

        .text {
          background: $color_nft_name;
          border-radius: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: $color_white;
          padding: 4px 8px;
        }

        img {
          margin-left: 10px;
        }
      }
    }

    .footer {
      &__text {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: $color_white;
        padding-top: 18px;
      }

      &__sub {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $color_titan_white;
        padding-top: 6px;
      }
    }
  }
}
