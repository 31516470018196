body {
  margin: 0;
}

div.ant-typography {
  margin-bottom: 0px;
}

p {
  margin-bottom: 0px;
}
.nft-id {
  display: flex;
  gap: 5px;
}
