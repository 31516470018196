.total-pool {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  .reserve_red {
    color: red;
  }
}
.pool-detail-page {
  &__header {
    display: flex;
    justify-content: space-between;
    button {
      height: 35px;
    }
  }
  .contract-address-pool-detail {
    display: flex;
  }

  .group-button-detail {
    display: flex;
    .delete-button {
      height: 35px;
      width: 110px;
      color: #ef7224;
      border: 1px solid #ef7224;
      display: flex;
      align-items: center;
      border-radius: 5px;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .pool-detail-content {
    margin-top: 20px;
    .pool-detail-box-wrapper {
      margin-bottom: 30px;
      position: relative;
      width: 100%;
      font-size: 14px;
      border-radius: 5px;
      transition: background-color 0.4s;
      border: 1px solid black;
      .box-content {
        position: absolute;
        top: -14px;
        margin-left: 16px;
        padding: 1px 8px;
        background: #fff;
        border-radius: 5px;
        transition: background-color 0.4s;
        font-size: 16px;
        font-weight: 600;
      }
      .pool-content {
        padding: 20px 50px;
        .title {
          font-weight: 600;
        }
      }
    }
    .pool-title {
      display: flex;
      justify-content: space-between;
      .pool-name {
        color: #2d2d2d;
        font-weight: 700;
        font-size: 20px;
      }
      .pool-status-finished,
      .pool-status-draft,
      .pool-status-waiting,
      .pool-status-live {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        border-radius: 30px;
        gap: 10px;
        .pool-dot {
          border-radius: 50px;
          width: 7px;
          height: 7px;
        }
      }
      .pool-status-finished {
        background: #93cbff;
        .pool-dot {
          background: #005baf;
        }
      }
      .pool-status-draft {
        background: #bfbfbf;
        .pool-dot {
          background: #837f7f;
        }
      }
      .pool-status-waiting {
        background: #ffe5b2;
        .pool-dot {
          background: #c58913;
        }
      }
      .pool-status-live {
        background: #c1fda4;
        .pool-dot {
          background: #2f8f00;
        }
      }
    }
  }
}
.pool-status-text-finished {
  color: #0085ff;
}
.pool-status-text-draft {
  color: #837f7f;
}
.pool-status-text-waiting {
  color: #ffa900;
}
.pool-status-text-live {
  color: #4adb03;
}
.deposit-button {
  height: 35px;
}
.group-button-staking-list {
  display: flex;
  .deposit-button {
    height: 35px;
    width: 110px;
    color: #ef7224;
    border: 1px solid #ef7224;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }
}
