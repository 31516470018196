.loading-metamask-modal {
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    // text-transform: capitalize;
    color: $color_black;
    padding-bottom: 44px;
    font-weight: 600;
  }

  .logo {
    height: 64px;
    width: 69px;
    margin-bottom: 43px;
  }
}
