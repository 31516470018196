.warning-banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $color_oasis;
  height: 63px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 30px;

  img {
    padding-right: 12px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.015em;
    color: $color_trout;
  }
}
