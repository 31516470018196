.create-whitelist-modal {
  padding: 36px;
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
    padding-bottom: 12px;
  }
  .group-button {
    text-align: center;
    margin-top: 40px;
    button {
      width: 200px;
    }
  }
  .whitelist-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .group-button-upload {
      display: flex;
      gap: 10px;
      button {
        height: 35px !important;
      }
    }
  }
  .list-address {
    margin-top: 20px;
    width: 100%;
    overflow-y: auto;
    height: 490px;
    border: 1px solid $color_trout_0_3;
    padding: 20px;
    .empty-data {
      text-align: center;
      margin-top: 200px;
      font-style: italic;
      color: $color_trout;
    }
    .address-error {
      color: red;
    }
  }
  .error-message {
    color: red;
  }
}

.delete-whitelist-modal {
  padding: 36px;
  .content-delete {
    text-align: center;
  }
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
    padding-bottom: 12px;
  }
  .group-button {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    justify-content: center;
    button {
      width: 100px;
    }
  }
  .whitelist-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .group-button-upload {
      display: flex;
      gap: 10px;
      button {
        height: 35px !important;
      }
    }
  }
  .list-address {
    margin-top: 20px;
    width: 100%;
    overflow-y: auto;
    height: 490px;
    border: 1px solid $color_trout_0_3;
    padding: 20px;
    .empty-data {
      text-align: center;
      margin-top: 200px;
      font-style: italic;
      color: $color_trout;
    }
    .address-error {
      color: red;
    }
  }
  .error-message {
    color: red;
  }
}
.modal-create-whitelist {
  .ant-modal {
    top: 50px;
  }
}

.list-address-modal {
  padding: 36px;
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.005em;
    color: $color_mine;
    padding-bottom: 12px;
  }
  .title-list-adress {
    height: 40px;
    background-color: rgb(192, 188, 188);
    font-size: 16px;
    font-weight: 600;
  }
  .ant-row {
    padding-left: 15px;
    align-items: center;
    margin-top: 5px;
  }
  .list-adress {
    max-height: 540px;
    overflow-y: auto;
  }
}
