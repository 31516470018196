.app-sider {
  background: $color_background !important;
  padding-bottom: 0px;
  border-right: 1px solid $color_gray;
  height: 100%;
  position: fixed;
  &__top {
    width: 100%;
    padding: 20px;
    display: inline-flex;
    align-items: center;

    .top {
      &__link {
        display: inline-flex;
        align-items: center;
      }

      &__logo {
        height: 60px;
        width: 100%;
        max-width: 120px;
        object-fit: contain;
      }

      &__text {
        font-size: 16px;
        line-height: 16px;
        text-transform: capitalize;
        color: $color_black;
        margin-left: 6px;
      }

      &__bar {
        cursor: pointer;
      }
    }
  }

  &__menu {
    .ant-menu-title-content {
      margin-left: 15px;
    }

    &--item {
      height: 60px;
      margin-top: 0px;
      padding-left: 30px !important;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.015em;

      svg {
        path {
          fill: rgba(119, 126, 144, 1);
          fill-opacity: 0.5;
        }
      }
    }

    .ant-menu-item-selected {
      background-color: transparent !important;
      position: relative;
      color: $color_scarlet;

      svg {
        path {
          fill-opacity: 1;
          fill: $color_scarlet;
        }
      }
    }
  }
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.app-sider__menu {
  background: $color_background;
  border-right: none;
}
