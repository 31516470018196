.dashboad-sale-analytics {
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $color_trout;
    }

    .filter {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .chart {
    height: 400px !important;

    .recharts-legend-wrapper {
      display: none;
    }
  }

  .chart-tooltip {
    background: #FFFFFF;
    color: #ef7224;
    padding: 10px;
    border: solid 1px #FE9E89;
    border-radius: 5px;
  }  
}