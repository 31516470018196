.table {
  margin-top: 20px;

  .ant-table-placeholder {
    &:hover {
      > td {
        background-color: unset !important;
      }
    }
  }

  .ant-table-container {
    &::before {
      display: none;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: $color_mercury;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height, or 150% */

      letter-spacing: 0.015em;

      /* Black */

      color: $color_black;

      .ant-table-column {
        &-title {
          flex: unset;
        }

        &-sorters {
          justify-content: flex-start;
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-column-sort {
      background-color: unset;
    }
  }

  .ant-pagination {
    display: flex;
    width: 100%;
    margin-top: 25px;
    align-items: center;
    justify-content: flex-end;

    .ant-select {
      .ant-select-selector {
        padding: 1px 10px;
        height: auto;

        .ant-select-selection-item {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }

    &-disabled {
      button {
        svg {
          opacity: 0.5;
        }
      }
    }

    .ant-pagination-options {
      order: -1;
      flex: 1;
      border-radius: 10px;

      .ant-select {
        background: $color_white;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid $color_gray;

        .ant-select-selection-item {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.015em;
          color: $color_waterloo;
        }
      }
    }

    .ant-pagination-item-active {
      font-weight: 400;
      background: $color_black;
      border-radius: 20px;
      border: unset;

      & a {
        color: $color_white;
      }
    }
  }
}
.action-column {
  display: flex;
  gap: 20px;
  span:nth-child(1) {
    color: rgb(100, 100, 245);
  }
  span:nth-child(2) {
    color: #ef7224;
  }
  span {
    cursor: pointer;
  }
}
