.setting-fee-rate {
  width: 100%;
  form {
    button {
      margin-top: 17px;
      height: fit-content;
    }
  }
}

.fee-rate-attribute {
  width: 40%;
  margin-top: 17px;

  &__label {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-item {
    margin-top: 20px;

    .field {
      position: relative;
      width: 20%;
    }
  }

  .field__span {
    position: absolute;
    top: 10px;
    right: -20px;
  }
}
